/*  hiding react-gauge needle */
.needle {
	display: none;
}

/* formatting react tour container */
.reactour__helper {
	background: linear-gradient(0deg,
			rgba(118, 73, 160, 0.14),
			rgba(118, 73, 160, 0.14)),
		#fcfcfc;
	border-radius: 28px;
}

.pathBox {
	width: 600px;
}

.pathBox svg {
	width: 100%;
	height: auto;
}

#desktop-triangleShape {
	stroke: black;
	stroke-width: 5;
	stroke-linejoin: round;
}

#mobile-triangleShape {
	stroke: black;
	stroke-width: 5;
	stroke-linejoin: round;
}

.speedometer {
	max-width: 100% !important;
	max-height: 100% !important;
}

.current-value {
	display: none;
}

.parser-control a {
	color: var(--selected-border-color);
	text-decoration: none;
	cursor: pointer;
}

/*===== Progress Bar =====*/
.progress-bar {
	width: 100%;
	height: 100%;
}

.progress-bar .track,
.progress-bar .fill {
	fill: rgba(0, 0, 0, 0);
	stroke-width: 3;
	transform: rotate(90deg) translate(0px, -80px);
	stroke-linecap: square;
}

.progress-bar .track {
	stroke: #ffeaac;
}

.progress-bar .fill {
	stroke-dasharray: 219.99078369140625;
	stroke-dashoffset: -219.99078369140625;
	transition: stroke-dashoffset 1s;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
	display: none;
}

/* * {
	scrollbar-width: none;
} */

.disable-hyperlink {
	text-decoration: none;
}

/*Grid Layout override css*/
.layoutJSON {
	/*border: 1px solid black;*/
	margin-top: 10px;
	padding: 10px;
}

.react-grid-item:hover {
	background: rgba(106, 117, 117, 0.08);
}

.react-grid-item .add {
	cursor: pointer;
}

.react-grid-dragHandleExample {
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
}

.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
}

.react-grid-item.cssTransforms {
	transition-property: transform;
}

.react-grid-item.resizing {
	z-index: 1;
	will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
	transition: none;
	z-index: 3;
	will-change: transform;
}

.react-grid-item.react-grid-placeholder {
	/*background: red;*/
	opacity: 0.2;
	transition-duration: 100ms;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.react-grid-item>.react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 0;
	right: 0;
	/*resize img*/
	/*background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");*/
	/*background: url("")*/
	background-position: bottom right;
	padding: 0 3px 3px 0;
	background-repeat: no-repeat;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: se-resize;
}

/* overrided css from mui-rte module to style pre-code blocks generate by text editor from markdown */
.DraftEditor-editorContainer div {
	background: none;
	padding: 0px;
}

.DraftEditor-editorContainer span {
	font-family: "RobotoRegular" !important;
	font-size: 16px;
	line-height: 24px;
	white-space: pre-wrap;
}

.DraftEditor-editorContainer ul {
	padding: 0 20px;
}

.DraftEditor-editorContainer img {
	max-width: 100%;
}

.rce-container-citem {
	display: block;
	flex-direction: column;
	min-width: auto !important;
	overflow: hidden;
}


.react-tel-input>.form-control {
	display: block;
	box-sizing: border-box;
	margin-top: 0;
	padding-left: 30px;
	height: 60px;
	padding-left: 42px;
	border-radius: 30px;
	background: #ffffff;
	color: #000;
	outline: 0;
	width: 100%;
	font-weight: 500;
	font-size: 16px;


}

.react-tel-input>.form-control::placeholder {
	font-family: PoppinsRegular, Helvetica, Arial, sans-serif;
	color: #667085;
}


.react-tel-input .flag-dropdown {
	outline: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	border-radius: 30px 0 0 30px;
}

.selected-flag {
	outline: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	border-radius: 30px 0 0 30px !important;
	background-color: #f5f5f5;
}

.react-tel-input .flag-dropdown.open {
	outline: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	border-radius: 30px 0 0 30px !important;
}

/*.react-grid-item:not(.react-grid-placeholder) {
  border: 1px solid #7C757F;
}*/

mark>a,
a>mark {
	display: inline-block;
	font-weight: 500;
	letter-spacing: 0.02857em;
	background: #5630B2;
	color: #ffffff;
	text-decoration: none;
	padding: 10px 24px;
	border-radius: 4px;
}