
.card-stack{
    position: relative;
    height: 300px;
    width: 300px;
}
  
  .card {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -150px;
    width: 200px;
    height: 300px;

    border-radius: 30px;
    background:linear-gradient(92.58deg, #7B61FF 0%, #b6a8f8 100%);
    box-shadow: -20px 20px 20px 0px rgb(0 0 0 / 30%);
    transform: rotateZ(-15deg) rotateY(45deg) rotateX(60deg);
    opacity: 0;
  }
  
  .card:nth-of-type(1) {
    margin-top: -150px;
    animation: card_fade_1 4s ease infinite;
  }
  
  .card:nth-of-type(2) {
    margin-top: -170px;
    animation: card_fade_2 4s ease infinite;
  }
  
  .card:nth-of-type(3) {
    margin-top: -190px;
    animation: card_fade_3 4s ease infinite;
  }
  
  .card:nth-of-type(4) {
    margin-top: -210px;
    animation: card_fade_4 4s ease infinite;
  }
  
  @keyframes card_fade_1 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
  }
  @keyframes card_fade_2 {
    20% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  @keyframes card_fade_3 {
    40% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
  @keyframes card_fade_4 {
    60% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
  }