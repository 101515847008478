.notificationPanelItem-enter {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(100%);
    transition: all ease 200ms;
}
.notificationPanelItem-enter-active {
    opacity: 1;
    filter: blur(0px);
    transform: translateY(0);
    transition: all ease 200ms;
}
.notificationPanelItem-exit {
    opacity: 1;
    transform: translateX(0);
    border-bottom: 2px solid red;
    filter: blur(0);
}
.notificationPanelItem-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    background: "red";
    transition: all ease 200ms;
    border-bottom: 5px solid red;
    filter: blur(10px);
}

  .jingle-enter-active {
    animation: jingle 1s ease;
  }
  
  @keyframes jingle {
    0% {
      transform: scale(1) rotate(0deg);
    }
    25% {
      transform: scale(1.5) rotate(20deg);
    }
    75%{
      transform: scale(1.5) rotate(-20deg);
    }
    100%{
        transform: scale(1) rotate(0deg);
      }
  }
  
