/* Poppins Font Family */
@font-face {
  font-family: PoppinsBlack;
  src: url(./assets/fonts/Poppins-Font/Poppins-Black.otf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(./assets/fonts/Poppins-Font/Poppins-Bold.otf);
}
@font-face {
  font-family: PoppinsExtraBold;
  src: url(./assets/fonts/Poppins-Font/Poppins-ExtraBold.otf);
}
@font-face {
  font-family: PoppinsExtraLight;
  src: url(./assets/fonts/Poppins-Font/Poppins-ExtraLight.otf);
}
@font-face {
  font-family: PoppinsLight;
  src: url(./assets/fonts/Poppins-Font/Poppins-Light.otf);
}
@font-face {
  font-family: PoppinsMedium;
  src: url(./assets/fonts/Poppins-Font/Poppins-Medium.otf);
}
@font-face {
  font-family: PoppinsRegular;
  src: url(./assets/fonts/Poppins-Font/Poppins-Regular.otf);
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url(./assets/fonts/Poppins-Font/Poppins-SemiBold.otf);
}
@font-face {
  font-family: PoppinsThin;
  src: url(./assets/fonts/Poppins-Font/Poppins-Thin.otf);
}

/* Roboto Font Family */
@font-face {
  font-family: RobotoBlack;
  src: url(./assets/fonts/Roboto-Font/Roboto-Black.ttf);
}

@font-face {
  font-family: RobotoBold;
  src: url(./assets/fonts/Roboto-Font/Roboto-Bold.ttf);
}

@font-face {
  font-family: RobotoLight;
  src: url(./assets/fonts/Roboto-Font/Roboto-Light.ttf);
}

@font-face {
  font-family: RobotoMedium;
  src: url(./assets/fonts/Roboto-Font/Roboto-Medium.ttf);
}

@font-face {
  font-family: RobotoRegular;
  src: url(./assets/fonts/Roboto-Font/Roboto-Regular.ttf);
}
@font-face {
  font-family: RobotoThin;
  src: url(./assets/fonts/Roboto-Font/Roboto-Thin.ttf);
}

@font-face {
  font-family: RobotoCondensedBold;
  src: url(./assets/fonts/Roboto-Font/RobotoCondensed-Bold.ttf);
}

@font-face {
  font-family: RobotoCondensedLight;
  src: url(./assets/fonts/Roboto-Font/RobotoCondensed-Light.ttf);
}

@font-face {
  font-family: RobotoCondensedRegular;
  src: url(./assets/fonts/Roboto-Font/RobotoCondensed-Regular.ttf);
}
