
  .loadingAnimation {
    /*height: 20px;
    width: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;*/
    position: relative;
    height: 20px; /* Dot Height */
    width: 100%;
  }
  .loadingAnimation--dot {
    animation-name: loadingAnimation;
    animation-timing-function: ease-in-out;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;
    left: 0;
  }
  .loadingAnimation--dot:first-child {
    
    background-color: #bdb0ff;
    opacity:.5;
    animation-delay: 0.5s;
  }
  .loadingAnimation--dot:nth-child(2) {
    background-color: #b0a0ff;
    opacity:.6;
    animation-delay: 0.4s;
  }
  .loadingAnimation--dot:nth-child(3) {
    background-color: #a390ff;
    opacity:.7;
    animation-delay: 0.3s;
  }
  .loadingAnimation--dot:nth-child(4) {
    background-color: #9581ff;
    opacity:.8;
    animation-delay: 0.2s;
  }
  .loadingAnimation--dot:nth-child(5) {

    background-color: #8871ff;
    opacity:.9;
    animation-delay: 0.1s;
  }
  .loadingAnimation--dot:nth-child(6) {
    background-color: #7b61ff;
    animation-delay: 0s;
  }

  
  @keyframes loadingAnimation {
    15% {
      left: 0
    }
    45% {
      left: calc(100% - 20px);
    }
    65% {
      left: calc(100% - 20px);
    }
    95% {
      left: 0
    }
  }