:root {
  --poppins-regular: "PoppinsRegular";
  --poppins-medium: "PoppinsMedium";

  --roboto-regular: "RobotoRegular";
  --font-style-normal: normal;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-17: 17.5px;
  --font-size-22: 22px;
  --font-size-34: 34.18px;
  --font-size-64: 64px;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --line-height-20: 20px;
  --line-height-21: 21px;
  --line-height-24: 24px;
  --line-height-26: 26px;
  --line-height-28: 28px;
  --line-height-44: 44px;
  --line-height-76: 76px;
  --line-height-130-percent: 130%;
  --line-height-150-percent: 150%;
  --line-height-or-text: 0.1em;
  --home-title-spacing: -0.5px;
  --home-sub-text-spacing: 0.1px;
  --terms-sub-text-spacing: 0.25px;
  --service-box-label-spacing: -0.02em;
  --white-color: #ffffff;
  --login-btn-color: #7649a0;
  --sub-text-color: #0a141b;
  --black-color: #000000;
  --gray-bg: #fcfcfc;
  --home-background: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);
  --home-blue-background: #f5f8fa;
  --stepper-connector-completed-color: #7649a0;
  --selected-border-color: #7649a0;
  --icon-border-radius: 1818.18px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Reusable Common classes */

.d-flex {
  display: flex;
}
.float-right {
  float: right;
}
.align-items-center {
  align-items: center;
}
.overflow-y {
  overflow-y: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.position-absolute {
  position: absolute;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.ml-auto {
  margin-left: auto;
}
.pr-8 {
  padding-right: 8px;
}
.color-red {
  color: red;
}
.w-100 {
  width: 100%;
}
.h-80 {
  height: 80%;
}
.p-24 {
  padding: 24px;
}

::placeholder {
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  color: #a1acac;
  opacity: 1;
}

:-ms-input-placeholder {
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  color: #a1acac;
}

::-ms-input-placeholder {
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  color: #a1acac;
}
/*
.container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 1;
  left: 0;
  height: 16px;
  width: 16px;
  background: #f5f8fa;
  border: 1px solid #a1acac;
  box-sizing: border-box;
  border-radius: 4px;
}

.container input:checked ~ .checkmark {
  background: blue;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}*/
.error-text {
  color: #ba1b1b;
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: 150%;
}

.btn-disabled {
  background: rgba(31, 31, 31, 0.12);
  color: #1d1b1e;
  opacity: 0.38;
}
.btn-enabled {
  background: var(--home-background);
  color: var(--white-color);
  cursor: pointer;
}
.error-border {
  border: 1px solid red;
}
.input-border {
  border: 1px solid #a1acac;
}

.pl-10 {
  padding-left: 10px;
}
.mt-237 {
  margin-top: 237px;
}

.error-toast.Toastify__toast--default {
  background: #ff515b;
  width: 100%;
}

.error-toast.Toastify__toast {
  background: #ff515b;
  width: 100%;
  font-size: 16px;
  font-family: var(--roboto-regular);
  line-height: 20px !important;
  padding: 8px;
  right: 0px !important;
  color: var(--white-color);
}
.error-toast.Toastify__toast--rtl {
  background: #ff515b;
  width: 100%;
}

.success-toast.Toastify__toast--default {
  background: #5e9c9c;
  width: 100%;
}
.success-toast.Toastify__toast {
  background: #5e9c9c;
  width: 100%;
  font-size: 16px;
  font-family: var(--roboto-regular);
  line-height: 20px !important;
  padding: 8px;
  right: 0px !important;
  color: var(--white-color);
}
.success-toast.Toastify__toast--rtl {
  background: #5e9c9c;
  width: 100%;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
* .SOSShowAllScroll, * .SOSShowHorizontalScroll, * .SOSShowVerticalScroll{
  scrollbar-width: thin !important;
}
.SOSShowAllScroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.SOSShowHorizontalScroll::-webkit-scrollbar{
  width: 0;
  height: 10px;
} 
.SOSShowVerticalScroll::-webkit-scrollbar{
  width: 10px;
  height: 0;
}

.SOSShowHorizontalScroll::-webkit-scrollbar-track, .SOSShowVerticalScroll::-webkit-scrollbar-track, .SOSShowAllScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
  border-radius: 10px;
}

/*Added class to add border to react player*/
.react-player video {
  border: 1px solid rgba(124, 117, 127, 0.16);
  border-radius: 8px; 
}

.react-player-discovery video {
  border-radius: 8px;
}


/*.circular-cropper .cropper-crop-box, .cropper-view-box {
  border-radius: 50%;
}
.normal-cropper .cropper-crop-box, .cropper-view-box {
  border-radius: unset;
}*/
/*.circular-cropper .cropper-view-box, .cropper-face {
  border-radius: 50%;
}*/
/*.circular-cropper .cropper-view-box {
	box-shadow: 0 0 0 1px #39f;
	border-radius: 50%;
	outline: 0;
}
.circular-cropper .cropper-face {
	background-color:inherit !important;
}
.circular-cropper .cropper-view-box {
	outline:inherit !important;
}*/

.circular-cropper .cropper-crop-box, .cropper-view-box {
  border-radius: 50%;
}

.circular-cropper .cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.circular-cropper .cropper-face {
background-color:inherit !important;
}

.circular-cropper .cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {
display:none !important;
}

.circular-cropper .cropper-view-box {
outline:inherit !important;
}






.normal-cropper .cropper-view-box, .cropper-face {
  border-radius: unset;
}


/*Adding this style as was unable to customize static datepicker through emotion , createstyles etc...*/
.MuiPickerStaticWrapper-root {
  /*background: linear-gradient(
      0deg,
      rgba(118, 73, 160, 0.05),
      rgba(118, 73, 160, 0.05)
    ),
    #fcfcfc;*/
  background-color: #ffffff !important;
}

input[type="range" i]:disabled {
  background-color: initial;
  color: #5630B2;
}
[aria-label="my-block"] {
  float: right;
}



.about-video-player video{
  min-height: 500px;
}

.autofill-input:-webkit-autofill {
  -webkit-text-fill-color: #ffff;
}

.ql-tooltip {
  left: 0% !important;
  transform: translate(-50%, -50%);
}
.editor-container .ql-container {
  min-height: 200px;
  /* border: none !important; */
  border: 1px solid #D0D5DD !important;
  border-radius: 8px;
}
.editor-container .ql-toolbar {
  border: none !important}


.profile-container .ql-container {
  min-height: 200px;
  /* border: none !important; */
  border:none !important;
  font-size: 16px;
  font-family: var(--poppins-medium);
  line-height: 20px !important;
  /* border-radius: 30px;
  background-color: white; */
}

.profile-container .ql-toolbar {
  border: none !important
}


.quill-editor .ql-toolbar {
  border: none !important
}

.ql-editor {
  font-size: 16px !important;
}

.ql-editor * {
  font-size: 16px !important;
}
.feedHtmlContainer * {
  font-size: 16px !important;
}
.profile-editor #mui-rte-editor{
    margin-left: 12px;
  }

  .graph-svg {
    display: flex;
    max-width: 430px;
    margin: auto;
  }