.wysiwyg-underline{
    text-decoration: underline;
}

.MuiTypography-h1{
    margin-bottom: 24px;
}

.MuiTypography-h2{
    margin-bottom: 16px;
}

.MuiTypography-h3{
    margin-bottom: 8px;
}

.MuiTypography-h3, .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6{
    margin-bottom: 8px;
}